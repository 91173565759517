<script>
export default {
  inheritAttrs: false
}
</script>
<script setup>
const props = defineProps({
  gray: Boolean,
  withBorderTop: Boolean,
  noPaddings: Boolean,
  noOuterPaddings: Boolean,
  outerPadding: String,
  withBorderBottomRounded: String,
  customBackground: String,
  customPosition: String
})
</script>

<template>
  <div class="w-full" :class="{'bg-gray-200': gray}">
    <div class="mx-auto container min-w-80 transition-[max-width]">
      <div :class="[
        {
          'px-4 sm:px-8': !props.noOuterPaddings && !props.outerPadding,
          [props.outerPadding]: props.outerPadding,
          [props.customBackground]: props.customBackground,
          [props.withBorderBottomRounded]: props.withBorderBottomRounded,
          [props.customPosition]: props.customPosition
        }
      ]">
        <div v-if="withBorderTop" class="border-t border-t-gray-400"></div>
        <div :class="[$attrs.class,{'pt-12 pb-12':!noPaddings}]">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>
